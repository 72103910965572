import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom"
import './App.css';
import Navigation from './Navbar';
import Top from './Top';
import Offers from './Offers';
import Projects from './Projects';
import Reviews from './Reviews';
import Contact from './Contact';
import Footer from './Footer';
import Project_Raffle from './Project_Raffle'
import Project_AKA_Thailand from './Project_AKA_Thailand'
import Project_Gelatoverse from './Project_Gelatoverse'
import AuditingProcess from './AuditingProcess'

import { Container, Row, Col } from 'react-bootstrap'
 

function App() {

  function reveal() {
    var reveals = document.querySelectorAll(".reveal, .revealRight, .revealPop");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);

  // To check the scroll position on page load
  reveal();

  return (
    <BrowserRouter>
        <Container className="App" fluid id="wrapper">
          <Routes>
            <Route path="/" element={
              <>
                <Navigation />
                <Top />
                {/* <Offers /> */}
                {/* <Projects /> */}
                {/* <Reviews /> */}
                {/* <Contact text={"Got any questions? Don't hesitate to reach out to get a Quote estimation for FREE."}/> */}
                <Footer /> 
              </>
            } />
            {/* <Route path="/auditing-process" element={
              <>
                <Navigation projectPage />
                <AuditingProcess></AuditingProcess>
                <Footer projectPage /> 
              </>
            } />
            <Route path="/project-raffle" element={
              <>
                <Navigation projectPage />
                <Project_Raffle></Project_Raffle>
                <Footer projectPage />
              </>
            } />
            <Route path="/project-aka-thailand" element={
              <>
                <Navigation projectPage />
                <Project_AKA_Thailand></Project_AKA_Thailand>
                <Footer projectPage /> 
              </>
            } />
            <Route path="/project-gelatoverse" element={
              <>
                <Navigation projectPage />
                <Project_Gelatoverse></Project_Gelatoverse>
                <Footer projectPage /> 
              </>
            } /> */}
          </Routes>
        </Container>
    </BrowserRouter>
  );
}

export default App;

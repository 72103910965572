import { Row, Col, Button } from 'react-bootstrap'

const Navigation = ({projectPage}) => {
    const buttonOnClick = () => {
        console.log("Click Button")
        var ex = document.getElementById('ex');
        ex.click();
    }

    return (
        <Row className="navigation">
            <Col className="logoCol col-7 col-md-5">
                {projectPage ? ( <a href="/" className="logo">LinkoLabs</a>
                ) : ( <a href="#top" className="logo">LinkoLabs</a> )}
                <div className="slogan">Empowering Your Web3 Dreams</div>
            </Col>
            <Col className="m-0 p-0 col-5 col-lg-5">
                    <div className="navbarLinks d-flex justify-content-end align-items-center">
                        <div className="d-none d-xl-block">
                            {projectPage ? ( 
                                <>
                                </>
                            ) : ( 
                                <>
                                    <a href="#offers" className="navbarElement">Offers</a>
                                    <a href="#projects" className="navbarElement">Projects</a>
                                    <a href="#reviews" className="navbarElement">Reviews</a>
                                </>
                            )}
                        </div>
                        <Button onClick={buttonOnClick} className="callButtonNavbar">Get a FREE Quote</Button>
                    </div>
            </Col>
            {/* <div className="trackDevice">
                <div class="d-block d-sm-none">Extra Small (xs)</div>
                <div class="d-none d-sm-block d-md-none">Small (sm)</div>
                <div class="d-none d-md-block d-lg-none">Medium (md)</div>
                <div class="d-none d-lg-block d-xl-none">Large (lg)</div>
                <div class="d-none d-xl-block d-xxl-none" >X-Large (xl)</div>
                <div class="d-none d-xxl-block" >XX-Large (xxl)</div>
            </div> */}
        </Row>
    )

}

export default Navigation;